<template>
    <div class="popup">
        <div class="box">
            <div class="popTitle popTitleArea">
                <p>환자 등록 및 목록 조회</p>
                <div class="buttonArea">
                    <button class="x" @click="handleClose"><i class="micon">close</i></button>
                </div>
            </div>
            <div class="popupContentArea">
                <PatientListCompo ref="patientListCompo" :isStep1="true" :initSelect="true"/>
                <span class="noticeText">※ 이관 승인 대기 상태 환자 선택 불가</span>
                <div class="btnW popBtn">
                    <button class="btn default" @click="handleCancel" v-ripple>{{CONST_CANCEL}}</button>
                    <button class="btn solid" @click="patientSelected()" v-ripple>AI 분석 시작</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../plugins/EventBus';

export default {
    middleware: "auth",
    components: {
        PatientListCompo: () => import('../components/patientListCompo.vue'),
    },
    props: {},
    data() {
        return {};
    },
    watch: {},
    created() {
        // 팝업이 생성될 때마다 selectedTargetInfo 초기화
        this.resetSelection();
        EventBus.on('closePopup', this.resetSelection);
    },
    mounted() {},
    beforeDestroy() {
        EventBus.off('closePopup', this.resetSelection);
    },
    destroyed() {},
    methods: {
        handleClose() {
            this.resetSelection();
            this.$emit('closePopup', false);
        },
        handleCancel() {
            this.resetSelection();
            console.log("Canceled: selectedTargetInfo has been cleared");
            this.$emit('closePopup', false);
        },
        patientSelected() {
            const selectedTargetInfo = JSON.parse(localStorage.getItem("selectedTargetInfo"));
            console.log("patientSelected: selectedTargetInfo", selectedTargetInfo);
            if (selectedTargetInfo && selectedTargetInfo.no) {
                this.$emit('selectedTargetInfo', selectedTargetInfo);
                this.$emit('closePopup', false);
                EventBus.emit('reading');
            } else {
                this.showPopup("분석할 환자를 선택해주세요.");
            }
        },
        resetSelection() {
            localStorage.removeItem("selectedTargetInfo");
            console.log("resetSelection: selectedTargetInfo has been cleared");
            if (this.$refs.patientListCompo) {
                this.$refs.patientListCompo.resetSelection();
            }
        }
    },
    computed: {},
};
</script>
<style>
    .noticeText{display:block; padding:0 24px 24px 24px; font-size: 1.4rem;}
</style>